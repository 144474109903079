.main {
  // background:url('../images/bg-image.jpg') no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  overflow:auto;
  display:flex;
  flex-direction: column;
  padding-bottom: 2.5rem;
  // @include bp(sm){
  //   background:$white;
  //   background-image:none;
  //   padding-bottom: 0;
  //   align-items: inherit;
  // }
  background-size: cover;
  background-attachment: fixed;
  // background: linear-gradient(
  //   90deg,
  //   #10121e 0.39%,
  //   #22273b 22.29%,
  //   #2e3552 51.46%,
  //   #292e4b 75.21%,
  //   #181d30 100%
  // );

  .back-btn-un {
    position: absolute;
    left: 15px;
    cursor: pointer;
    top: 30px;
    padding: 15px;
    width: 40px;
    z-index: 100;
    height: 40px;
    text-align: center;
    line-height: 40px;
    display: none;
    @include bp(sm) {
      display: block;
    }
  }

  .custom-wrapper {
    margin: auto;
    width: 360px;
    position: relative;
    display: flex;
    flex-direction: column;
    @include bp(sm){
      height:100%;
      width: 100%;
    }
  }
  .login-content {
    width: 100%;
    min-height:380px;
    color: $white;
    height: 100%;
    padding: 2.5rem 1.25rem 1.25rem;
    @include radius(1.875rem);
    @include shadow(0 0 0.625rem 0 rgba(0, 0, 0, 0.16));
    margin: auto;
    background: #292f47;
    background: linear-gradient(95.55deg, #2b3c60 13.79%, #2d4876 84.34%);
    background: -moz-linear-gradient(95.55deg, #2b3c60 13.79%, #2d4876 84.34%);
    background: -webkit-linear-gradient(
      95.55deg,
      #2b3c60 13.79%,
      #2d4876 84.34%
    );
    background: linear-gradient(95.55deg, #2b3c60 13.79%, #2d4876 84.34%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#292f47', endColorstr='#3261a5', GradientType=1);
    /* IE6-9 fallback on horizontal gradient */
    @include bp(sm){
      @include radius(1.875rem 1.875rem 0 0);
      width: 96%;
    }

    h2 {
      text-transform: uppercase;
      margin-bottom: 1.5rem;
      @include font(1.125rem, 700, $white);
    }
  }
  .logo {
    padding: 2.5rem 0px;

  @include bp(sm){
    display:none;
  }
  }
  .form-control {
    background: #3e4359;
    color: $white;
  }
  input:-internal-autofill-selected {
    background-color: #3e4359 !important;
  }
  label {
    @include font(0.875rem, 300, $white);
  }
  a {
    @include font(0.875rem, 400, $cyan);
    margin-top: 0.5rem;
  }
  p {
    @include font(0.875rem, 400, $white);
    margin: 1rem 0;
  }
  span{
    margin:0.2rem 0;
  }
  .buttons {
    margin-top: 2.5rem;

    button {
      min-width: 160px;
    }
  }
}

.logo.device-logo{
  display:none;
  @include bp(sm){
    display:block;
  }
}
.web-logo{
  padding:1.5625rem 1.25rem 2.5rem;
  @include bp(sm){
    display:none;
  }
}

.header-section {
  display: none;
  
  @include bp(sm){
    display: flex;
    min-height: 7vh;
    border-radius: 0px 0px 30px 30px;
    margin-bottom: 4%;
    background: linear-gradient(100.78deg, #292f47 100%, #2b3c60 105.8%);

    #back_icon_mob {
      margin: 25px;
    }
  }
}
