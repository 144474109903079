.main-wrapper {.mat-drawer-container {
  min-width: 168px;
  max-width: 210px;
  //background-image: linear-gradient(164deg, #1c2237 81%, #3261a5 128%);
 //background-image: linear-gradient(to right, #114b6e, #10486a, #0e4567, #0d4363, #0b4060);
 background: linear-gradient(to left, #0B4060, #114B6E);
  @include radius(1.25rem);
  padding-top: 1.2rem;
  //padding-bottom:0.6rem;
  @include transition(all linear 0.4s);
  min-height: 100%;
.mat-drawer-inner-container{
  overflow:visible;
}
  .mat-drawer {
    margin-top:0.625rem;
    visibility: visible !important;
    position: relative ;
    @include transform(none );
    height: 100%;
    background-color:transparent;
    .mat-list-base{
      padding-top:0;

    a.mat-list-item,a {
      @include font(0.75rem, 300, $white);
      margin-bottom: 0.1rem;
      height: 44px;
      white-space: nowrap;
      overflow: hidden;
      @include hover-focus() {
        color: $primary;
        background: $black;
        .mat-icon {
          color: $primary;
        }
      }
      &.active,
      &:hover,
      &:active,
      &:focus {
        color: $seafoam;
        background: $navy2;
        .mat-icon {
          color: $seafoam;
          &.icon-home {
            &:before {
              content: "\e92d";
            }
          }
          &.icon-orders {
            &:before {
              content: "\e939";
            }
          }
          &.icon-prescription {
            &:before {
              content: "\e93e";
            }
          }
          &.icon-drug-search {
            &:before {
              content: "\e924";
            }
          }
          &.icon-find-pharmacy {
            &:before {
              content: "\e928";
            }
          }
          &.icon-claims {
            &:before {
              content: "\e91f";
            }
          }
          &.icon-membership {
            &:before {
              content: "\e936";
            }
          }
          &.icon-benefits {
            &:before {
              content: "\e914";
            }
          }
          &.icon-settings {
            &:before {
              content: "\e943";
            }
          }
          &.icon-alternate-therapy{
            &:before {
              content: "\e93c";
            }
          }
          &.icon-help {
            &:before {
              content: "\e92b";
            }
          }
          &.icon-form {
            &:before {
              content: "\e913";
            }
          }
          &.icon-shutdown {
            &:before {
              content: "\e912";
            }
          }
          &.icon-clinical-review {
            &:before {
              content: "\e915";
            }
          }
        }
      }
    }
  }

  }
  .submenu-block{
    position:relative;
  }
  .submenu{
    padding:0.5rem 0;
    margin-top:-0.1875rem;
    background:$navy2;
    @include transition(all linear 0.4s);
    display:none;
    li {
      padding: 0 1.1rem;
      &.active,
      &:hover,
      &:active,
      &:focus {
        color: $white;
        background: $navy2;
        a 
        &:hover,
        &:active,
        &:focus {
          background: transparent;
        }
      }
    }
    a{
      display: flex;
      align-items: center;
      padding-right: 0.5rem;
      height:30px !important;
    }
  }
  .arrow-icon{
    position: absolute;
    right: 0;
    top:0.8575rem;
    width: 1.875rem;
    padding-left: 0.5rem;
    z-index: 1;
    cursor:pointer;
    mat-icon{
      margin-right: -0.625rem;
      @include font(0.8rem);
    }
  }

  .open-submenu{
    .submenu{
      display:block;
    }
    .arrow-icon{
      .icon-down-arrow{
        &:before{
          content: "\e947";
        }
      }
    }
  }

}
}
.medium-text-size .main-wrapper .mat-drawer-container .arrow-icon {
  top: 0.6575rem;
}
.large-text-size .main-wrapper .mat-drawer-container .arrow-icon {
  top: 0.4975rem;
}
.mat-menu-panel{
  min-height: 48px !important;
  .mat-menu-content, .mat-menu-content:not(:empty){
    padding-top:0;
  padding-bottom: 0;
.sub-menu{
  padding:0.5rem;
  background:$black;
@include transition(all linear 0.4s);
  .mat-menu-item{
    height:auto;
  margin-bottom: 0.2rem;
  line-height: 1.875rem;
  }
  a{
    padding-right: 0.5rem;
    height:30px !important;
    @include font(0.75rem,300,$white);
    &:hover,&:active,&.active{
      color:$primary;
    }
  }
}
}
}
  .back-btn {
    position: absolute;
    right: 0;
    cursor: pointer;
    &:after {
      content: "";
      position: absolute;
      background: $navy;
      width: 35px;
      height: 30px;
      @include radius(50%);
      right: -10px;
      z-index: 1;
      top: 0px;
    }
    mat-icon {
      @include font(0.83rem, 300, $gray-400);
      position: relative;
      z-index: 2;
      margin: 0;
      top: 4px;
      right: -15px;
    }
  }
  .submenu-block{
    a{
      display:none;
      &.setting-menu{
        display:none;
      }
    }
  }
  .close-sidenav {
    .mat-drawer-container {
      min-width: 44px;
      max-width: 44px;
    }

    .icon-left-arrow {
      &:before {
        content: "\e940";
      }
    }
    .arrow-icon{
      display:none;
    }
    .submenu{
      display:none !important;
    }

    .submenu-block{
      a{
        display:none;
        &.setting-menu{
          display:block;
        }
      }
    }
  }


.backdrop {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 11;
  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    @include translate(-50%, -50%);
  }
}

.mat-list-base .mat-list-item .mat-list-item-content{
  padding: 0 32px 0 16px!important;
}


