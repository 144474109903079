.mat-expansion-panel:not([class*=mat-elevation-z]){
  @include shadow(none);
  margin:0;
}

mat-expansion-panel{
  border-bottom: 1px solid $light-gray;
  .mat-expansion-panel-header{
    padding:0.8rem 1.2rem;
    height:auto;
      &.mat-expanded {
        height: auto;
        padding-bottom: 0.5rem;
    }
  }
  &:last-child{
    border-bottom: none;

  }
  mat-panel-title{
    @include font(0.8125rem,500,$secondary);
  }
  .mat-expansion-panel-body{
    padding:0.5rem 1.2rem 1.2rem;
    p{
      @include font(0.75rem,300,$secondary);
      margin:0;
    }
  }
}
.custom-page-width {
  .mat-expansion-panel-body{
    margin-top:0.5rem;
  }
}

