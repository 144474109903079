// Border-radius
@mixin radius($border-radius) {
  border-radius: $border-radius;
  -webkit-border-radius: $border-radius;
  -ms-border-radius: $border-radius;
  -moz-border-radius: $border-radius;
}

// Box-shadow
@mixin shadow($box-shadow) {
  box-shadow: $box-shadow;
  -webkit-box-shadow: $box-shadow;
  -ms-box-shadow: $box-shadow;
  -moz-box-shadow: $box-shadow;
}

// Transition
@mixin transition($transition) {
  transition: $transition;
  -webkit-transition: $transition;
  -moz-transition: $transition;
  -ms-transition: $transition;
}

// Transform
@mixin transform($transform) {
  transform: $transform;
  -webkit-transform: $transform;
  -moz-transform: $transform;
  -ms-transform: $transform;
}

// rotate
@mixin rotate($deg) {
  @include transform(rotate(#{$deg}deg));
}

// scale
@mixin scale($scale) {
  @include transform(scale($scale));
}

// translate
@mixin translate($x, $y) {
  @include transform(translate($x, $y));
}

// skew
@mixin skew($x, $y) {
  @include transform(skew(#{$x}deg, #{$y}deg));
}

// box
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

// Flex Width //
@mixin flex-width($value) {
  -ms-flex: 0 0 $value;
  flex: 0 0 $value;
  max-width: $value;
}

// Hover function
@mixin hover() {
  &:hover {
    @content;
  }
}
@mixin hover-focus() {
  &:hover,
  &:focus {
    @content;
  }
}
@mixin plain-hover-focus() {
  &,
  &:hover,
  &:focus {
    @content;
  }
}
@mixin hover-focus-active() {
  &:hover,
  &:focus,
  &:active {
    @content;
  }
}

// Text Color
@mixin text-color($color) {
  color: $color;
}

// Background Color
@mixin bg-color($color) {
  background: $color;
}

// Horizontal gradient, from left to right
@mixin gradient-x(
  $start-color: $secondary,
  $end-color: $primary,
  $start-percent: 0%,
  $end-percent: 100%
) {
  background-image: linear-gradient(
    to right,
    $start-color $start-percent,
    $end-color $end-percent
  );
  background-repeat: repeat-x;
}

// Vertical gradient, from top to bottom
@mixin gradient-y(
  $start-color: $primary,
  $end-color: $secondary,
  $start-percent: 0%,
  $end-percent: 100%
) {
  background-image: linear-gradient(
    to bottom,
    $start-color $start-percent,
    $end-color $end-percent
  );
  background-repeat: repeat-x;
}

// Push auto
@mixin push-auto {
  margin: {
    left: auto;
    right: auto;
  }
}

// Pseudo
@mixin pseudo($display: block, $pos: absolute, $content: "") {
  content: $content;
  display: $display;
  position: $pos;
}

// Placeholder
@mixin input-placeholder {
  &.placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
}

// background cover
@mixin cover-background {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

// List Reset
@mixin list-reset {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

// Font Styles
@mixin font($size: false, $weight: false, $text-color: false) {
  @if $size {
    font-size: $size;
    line-height: $size + 0.4rem;
  }
  @if $weight {
    font-weight: $weight;
  }
  @if $text-color {
    color: $text-color;
  }
}

// mat-icon 
@mixin mat-icon-font($size: false, $weight: false, $text-color: false) {
  @if $size {
    font-size: $size;
  }
  @if $weight {
    font-weight: $weight;
  }
  @if $text-color {
    color: $text-color;
  }
}

// Media Query
// Media Breakpoints

$sm-min: 576px;
$md-min: 768px;
$lg-min: 992px;
$xlg-min: 1200px;
$exlg-min: 1600px;
$xs: 575.98px;
$sm: 767.98px;
$md: 991.98px;
$lg: 1199.98px;
$exlg: 1599px;

@mixin bp($point) {
  @if $point == sm-min {
    @media screen and (min-width: $sm-min) {
      @content;
    }
  } @else if $point == md-min {
    @media screen and (min-width: $md-min) {
      @content;
    }
  } @else if $point == lg-min {
    @media screen and (min-width: $lg-min) {
      @content;
    }
  } @else if $point == exlg-min {
    @media screen and (min-width: $exlg-min) {
      @content;
    }
  } @else if $point == xlg-min {
    @media screen and (min-width: $xlg-min) {
      @content;
    }
  } @else if $point == xs {
    @media screen and (max-width: $xs) {
      @content;
    }
  } @else if $point == sm {
    @media screen and (max-width: $sm) {
      @content;
    }
  } @else if $point == md {
    @media screen and (max-width: $md) {
      @content;
    }
  } @else if $point == lg {
    @media screen and (max-width: $lg) {
      @content;
    }
  } @else if $point == exlg {
    @media screen and (max-width: $exlg) {
      @content;
    }
  }
}
