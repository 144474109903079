.primary-button.mat-button-base { 
  /*background: #E22908;
  background: -moz-linear-gradient(
    top,
    #f85a3e 0%,
    #fb664b 37%,
    #fe7158 66%,
    #fe7158 100%
  );
  background: -webkit-linear-gradient(
    top,
    #f85a3e 0%,
    #fb664b 37%,
    #fe7158 66%,
    #fe7158 100%
  );
  background: linear-gradient(
    to bottom,
    #f85a3e 0%,
    #fb664b 37%,
    #fe7158 66%,
    #fe7158 100%
  );*/
  background: -moz-linear-gradient(100.78deg, #2B826C 21%, #3ABA98 105.8%);
  background: -webkit-linear-gradient(100.78deg, #2B826C 21%, #3ABA98 105.8%);
  background: linear-gradient(100.78deg, #2B826C 21%, #3ABA98 105.8%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f85a3e', endColorstr='#fe7158',GradientType=0 ); /* IE6-9 */
  @include font(0.9rem, 700, $white);
  @include radius(1.25rem);
  border: none;
  padding: 0.6rem 1.5rem;
  min-width: 110px;
  @include shadow(none);
  &.primary-small-button {
    @include font(0.63rem, 700, $white);
    min-width: 3.75rem;
    line-height: 1.5rem;
    padding:0 0.5rem;
  }
  &.medium-btn {
    @include font(0.6875rem);
    line-height: 1.625rem;
    padding:0;
  }
  &.button-loader{
    padding-top:0.3125rem;
    padding-bottom: 0.3125rem;
  }
}
.primary-button.mat-button-base:disabled {
  color:$white;
  background: #b4b4b4; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #b4b4b4 24%,
    #bebebe 50%,
    #c8c8c8 95%,
    #c8c8c8 95%,
    #c8c8c8 95%,
    #c8c8c8 100%,
    #c8c8c8 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #b4b4b4 24%,
    #bebebe 50%,
    #c8c8c8 95%,
    #c8c8c8 95%,
    #c8c8c8 95%,
    #c8c8c8 100%,
    #c8c8c8 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #b4b4b4 24%,
    #bebebe 50%,
    #c8c8c8 95%,
    #c8c8c8 95%,
    #c8c8c8 95%,
    #c8c8c8 100%,
    #c8c8c8 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b4b4b4', endColorstr='#c8c8c8',GradientType=1 ); /* IE6-9 */
  cursor:default;
}

.delete-btn {
  mat-icon {
    color: $gray-600;
  }
}

.secondary-button.mat-button-base {
  background: transparent;
  @include font(0.9rem, 700, $darkseafoam);
  @include radius(1.25rem);
  border-color: $darkseafoam;
  min-width: 100px;
}

.secondary-button.mat-stroked-button {
  @include font(0.875rem);
  line-height:2.125rem;
  &.medium-btn {
    line-height: 1.5rem;
  }
  &.small-button {
    @include font(0.63rem, 700);
    min-width: 3.75rem;
    line-height: 1.3rem;
    padding:0;
  }
}


