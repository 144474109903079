.form-control{
height:36px;
background:$light-gray;
border:none;
@include font(0.9rem,600,$secondary);
@include radius(0.32rem);
padding-left:0.5rem;
padding-right:0.5rem;
width:100%;
    &:focus{
      outline:none;
    }
    @include input-placeholder{
      color:$gray-400;
    }
}

.search-block{
  position:relative;
  &:before{
    content: "\e941";
    font-family: 'icomoon';
    position:absolute;
    left: 10px;
    top: 8px;
    color:$gray-300;
    font-size: .78rem;
    z-index: 11;
  }
  .form-control{
    border-radius: 20px;
    padding: 0 0.5rem 0 1.8rem;
    width: 178px;
    color:$white;
    background:#04223a;
    border: none;
    height: 32px;
    @include font(0.75rem,300);
  }
}

.search-icon{
  position:relative;
  &:before{
    content: "\e941";
    font-family: 'icomoon';
    position:absolute;
    right: 10px;
    bottom: 6px;
    color:$secondary;
    font-size: 0.9375rem;
    cursor:pointer;
  }
}

.select-box{
  position:relative;
  select{
    background: #e9eaec url(../images/dropdown-arrow.png) no-repeat right;
    appearance:none;
    -webkit-appearance:none;
    cursor: pointer;
  }
  // &:before{
  //   content: "\e922";
  //   font-family: 'icomoon';
  //   position:absolute;
  //   right: 10px;
  //   top: 10px;
  //   color:$secondary;
  //   font-size: .78rem;
  //   z-index: 1;
  //   cursor: pointer;
  // }
}

.custom-box .form-group, .form-group{
  label{
    @include font(0.813rem,400,$secondary);
    display: block;
    margin-bottom: 0.2rem;
  }
  p{
    @include font(0.813rem,500,$secondary);
  }
}

.member-detail-card {
  margin-bottom: 15px;
  .form-group  {
    label {font-size: 1rem;}
    p {
      font-size: 1rem;
      font-weight: 600;
      margin: 10px 0;
    }
  }
}

.phi-not-shared p{
  @include font(16px !important, 400 !important, #292F47 !important);
  margin: 0 20px;
  .icon-information {
    position: relative;
    top: 2px;
  }
}
.family-profiles-tabs {
  width: calc(100% - 5%);
  .mat-tab-label-container {
    width: 100px !important;
  }
  .mat-tab-label {
    margin-right: 3rem !important;
  }
  .mat-tab-header {
    width: calc(100% - 4%);
    overflow: hidden;
  }
  .mat-tab-header-pagination-chevron {
    border-color: white !important;
  }
}

.mat-radio-button.mat-accent .mat-radio-inner-circle, .mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element:not(.mat-radio-persistent-ripple), .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-persistent-ripple, .mat-radio-button.mat-accent:active .mat-radio-persistent-ripple,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background{
  background:$green;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{
  border-color:$green;
}

.mat-slide-toggle{
  width:100%;
  .mat-slide-toggle-label{
    width:100%;
    display:flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content:space-between;
  }
  .mat-slide-toggle-content{
    margin-right:1rem;
  }
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb,
.mat-slide-toggle.mat-checked .mat-ripple-element{
  background-color: $white;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar{
  background-color: #548301;
}

.custom-radio{
  p{
    position:relative;
    & + p{
      left:-1.5625rem;
    }
  }
[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    width:225px;
    height:32px;
    display:flex;
    align-items: center;
    justify-content: center;
    @include radius(1.25rem);
    border:1px solid $lightgraytwo;
    @include font(0.9375rem,500,$dark-blue);
    cursor:pointer;
}
[type="radio"]:not(:checked) + label {
  background:$lightgray;
}
[type="radio"]:checked + label{
  background:linear-gradient(100.78deg, #2B826C 21%, #3ABA98 105.8%);
  border:0;
  color:$white;
  position:relative;
  z-index: 1;
  @include font(0.9375rem,700);
}
}
.filter{
  .custom-radio{
    position:relative;
    top:0.3125rem;
    [type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
  width:173px;
}
  }
}

.primary-button {
  background-image: linear-gradient(to right, #2b826c, #2f9077, #329e82, #36ac8d, #3aba98);
  min-width: 260px;
  padding: 30px;
  color: $white;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}
.secondary-button {
  background: $white;
  min-width: 260px;
  padding: 30px;
  color: $darkseafoam;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}
.link {
  color: $seafoam;
}

.no-data-found {
  color: #126AA5;
  font-size: 1.1rem;
  font-style: italic;
  padding: 0 17px;
  margin: 15px 0;
  display: block;
}