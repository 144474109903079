.cdk-overlay-container {
  .mat-dialog-container {
    min-width: 360px;
    @media screen and (max-width: 480px) {
      min-width: 100%;
    }
    padding: 0;
    @include radius(1.25rem);
    @include shadow(0 0 10px 0 rgba(0, 0, 0, 0.16));
    @include bp(md) {
      width: 100%;
    }
    p {
      color: $secondary;
      font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
      letter-spacing: normal;
    }
    ol{
      list-style-type: decimal;
      margin-left: 25px;
    }
    h4{
      font: 400 14px/20px Roboto, "Helvetica Neue", sans-serif;
      letter-spacing: normal;
    }
    h2 {
      border-bottom: 1px solid $gray-300;
      @include font (0.9375rem,700);
      padding: 0.8rem 0.5rem 0.5rem 1rem;
      margin: 0;
      align-items: center;

      .mat-dialog-title {
        @include font(0.9375rem, 700);
        margin-bottom: 0;
      }
    }
    .custom-block-width {
      width: 33.33%;
      .information-block {
        margin-bottom: 1rem;

        .form-group label {
          margin-bottom: 0.1rem;
        }
      }
      &.margin-auto {
        margin: 0 4rem;
      }
    }
    .view-detail {
      width: 750px;
      padding: 1.5rem 0 1.5rem 0rem;
    }

    .mat-dialog-title {
      @extend .d-flex;
    }
    .order-form-popup {
      .order-process {
        margin: 0.5rem 0;
        p {
          @include font(1rem !important);
        }
        .progress-block {
          p {
            @include font(0.8125rem !important);
          }
        }
      }
    }

    .mapouter {
      height: 319px;
      @include radius(0);
    }
  }
  .mat-dialog-content {
    margin: 0;
    .mat-radio-label {
      display: flex;
    }
    &.custom-height-modal {
      min-height: 460px;
    }
  }
  .mat-dialog-actions {
    margin: 1rem 0;
  }
  .close {
    mat-icon {
      @include mat-icon-font(0.75rem, 500, $secondary);
      margin: 0;
      cursor: pointer;
    }
  }

  .filter-box {
    width: 288px;
    @include shadow(0 0 10px 0 rgba(0, 0, 0, 0.16));
    @include radius(1rem);
    padding: 1rem 0 0;
    margin-top: 0.5625rem;
    &:before {
      content: "";
      position: absolute;
      /*top: -7px;
      right: 25px;*/
      top: -5px;
      right: 19px;
      border-bottom: 16px solid $white;
      border-left: 9px solid transparent;
      border-right: 9px solid transparent;
    }
    .filter-form {
      padding: 0 1.5rem 1rem;
    }
    h4 {
      border-bottom: 1px solid $gray-200;
      padding-left: 1.5rem;
      padding-bottom: 0.8125rem;
      @include font(0.9375rem);
    }
    .close {
      mat-icon {
        @include mat-icon-font(0.75rem);
      }
    }
    &.language-dropdown {
      width: 223px;
      margin-left: -2.625rem;
      &:before {
        left: 98%;
        right: inherit;
        top: -0.25rem;
      }
    }
    &.text-size-dropdown {
      &:before {
        left: 98%;
        right: inherit;
        top: -0.25rem;
      }
    }
    mat-icon {
      @include mat-icon-font(0.9rem, 500, $secondary);
      margin: 0;
    }

    .mat-list-base .mat-list-item {
      @include font(0.8125rem, 400, $secondary);
      height: auto;
      mat-icon {
        @include mat-icon-font(0.75rem);
      }
    }
    .mat-list-item-content {
      padding: 0;
    }

    .lang-dialog-heading {
      margin: 0 !important;
    }
  }

  .pricing-detail {
    h2 {
      margin: 0 -24px;
    }
  }

  /*Toaster css */
  .mat-snack-bar-container {
    background: transparent;
    padding: 0;
    min-height: auto;
    @include shadow(none);
    min-width: 225px;
    .toaster-bg {
      padding: 10px 15px;
      min-height: 40px!important;
      width: 100%;
      @include radius(0.625rem);
      @include font(0.9375rem, 400, $white);
      align-items: center;
      &.success {
        background: $success-color;
        .mat-icon {
          height: auto!important;
        }
      }
      &.error {
        background: $error-color;
      }
    }
  }
}
