.membership-list {
  .box-width{
    width:75%;
  }
  .custom-box{
    &.box-width{
      width:80%;
    }
  }
  .phinotshared-custom-box {
    width:85%;
  }
  .mat-tab-body-content {
    color: $white;
    height:auto;
    overflow:inherit;
    mat-expansion-panel .mat-expansion-panel-body p{
      margin:0.5rem 0;
      @include font(0.875rem,400);
    }
    .mat-expansion-panel-header-title{
      @include font(0.875rem);
    }
  }
  .mat-tab-label {
    @include font(0.9375rem, 400, $white);
    padding: 0;
    text-align: left;
    min-width: auto;
    margin-right: 4.375rem;
    justify-content: flex-start;
    &.mat-tab-label-active {
      opacity: 1;
      font-weight: $font-weight-medium;
    }
  }
  .mat-tab-nav-bar,
  .mat-tab-header {
    border-bottom: 1px solid $gray-900;
  }
  .mat-tab-group.mat-primary .mat-ink-bar,
  .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    background: $seafoam;
  }
  .membership-detail {
    padding: 0.5rem 1.5rem 0.5rem;
  }
  .membership-card {
    padding: 0.5rem 1.5rem 1rem;
    .download-id-card {
      width: 770px;
    }
    .card-image{
      margin-top: 0.5rem;
      width:100%;
      img {
        border: 1px solid #ccc;
        -webkit-border-radius: 0.8rem;
        -moz-border-radius: 0.8rem;
        border-radius: 0.8rem;
      }
    }
  }
  .custom-block-width {
    width: 33.33%;
  }
  .order-pharmacy-update {
    background: #FFF9C4;
    padding: 10px 20px;
    margin-top: 25px;
    -webkit-border-radius: 1.25rem;
    -moz-border-radius: 1.25rem;
    border-radius: 1.25rem;
    width: 80%;
    .update-cart-img {
      text-align: center;
      padding: 20px;
    }
    .update-cart-text {
      padding: 20px;
      h3 {
        color: #000;
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 10px;
      }
      p {
        color: #000;
        margin-bottom: 10px;
        font-size: 14px;
      }
    }
  }
}

.mat-tab-body.mat-tab-body-active{
  overflow-y: inherit !important;
}

.guest-tab {
  .mt-2.custom-box {
    margin: 0;
  }
  .mat-tab-label {
    color: black !important;
  }
  @media screen and (max-width: 1200px) {
    .custom-box {
      margin-top: 2.5rem !important;
    }
  }
}
@media screen and (max-width: 850px) {
  .guest-tab .mat-tab-label {
    min-width: auto;
    width: 120px;
    .mat-tab-label-content {
      white-space: normal!important;
    }
  }
}
@media screen and (max-width: 1366px) {
  .faq-tab-list .mat-tab-label {
    margin-right: 1.375rem;
  }
}
@media screen and (max-width: 850px) {
  .guest-tab .mat-tab-label {
    min-width: auto;
    width: 120px;
    .mat-tab-label-content {
      white-space: normal!important;
    }
  }
}