// Title Size
@each $size, $value in $title {
  .title-#{$size} {
    font-size: $value !important;
  }
}

// Icon Size
@each $size, $value in $icon-size {
  .icon-#{$size} {
    font-size: $value;
  }
}
// Colors
@each $colorName, $value in $colors {
  // Text Color
  .text-#{$colorName} {
    color: $value !important;
  }

  // Background Color
  .bg-#{$colorName} {
    background-color: $value;
  }
}

// Font Weight
@each $fw, $value in $font-weight {
  .text-w-#{$fw} {
    font-weight: $value !important;
  }
}
