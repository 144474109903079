.mat-toolbar {
  &.mat-toolbar-multiple-rows {
    min-height: 80px;
  }
  .mat-toolbar-row {
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    padding-left: 1.6rem;
    height: 100%;
    button {
      margin-right: 1rem;
      mat-icon {
        font-size: 24px;
        height: 24px;
        width: 24px;
      }
      .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
        right: -7px;
        background: $magenta;
      }
      .mat-badge-medium.mat-badge-above .mat-badge-content {
        top: -10px;
      }
      .mat-badge-medium .mat-badge-content {
        width: 18px;
        height: 18px;
        line-height: 18px;
        font-size: 10px;
      }
    }
  }
}

.filter-active {
  .mat-badge-medium .mat-badge-content {
    width: 11px;
    height: 11px;
    color: transparent;
  }
  .mat-badge-medium.mat-badge-above .mat-badge-content {
    top: 0.3125rem;
  }
  .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
    right: 0.1875rem;
    background-color: $magenta!important;
  }
}

.font-size-image {
  height: 24px;
  width: 28px;
}
