.order-process {
  background: $white;
  @include radius(1rem);
  padding: 0.8rem 1.5rem;
  margin-bottom: 1.5rem;
  p + p {
    max-width: 300px;
  }
  min-width: 340px;
}

.progress-container {
  .progress-block {
    position: relative;
    z-index: 2;
    min-width: 103px;
    text-align: center;
    p {
      margin: 0.5rem 0 0;
      @include font(0.9375rem, 400, $secondary);
    }
    .circle {
      background: $gray-400;
      @include radius(50%);
      height: 24px;
      width: 24px;
      margin: 0 auto;
      z-index: 1;
      position: relative;
      mat-icon {
        @include font(0.9rem, 400);
        margin: 0;
        @extend .d-flex;
      }
      &::after {
        content: "";
        background-color: #b4b4b4;
        position: absolute;
        top: 50%;
        left: -79px;
        transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        height: 2px;
        width: 79px;
        z-index: -1;
      }
      &.active {
        background: $green;
        &::after {
          content: "";
          background-color: $green;
        }
      }
    }
    &:first-child {
      .circle {
        overflow: hidden;
        &::after {
          content: none;
        }
      }
    }
  }
}

.cart-checkout-container {
  .progress-container {
    width: 100%;
    margin-bottom: 2rem;
    margin-left: -0.75rem;
    .progress-block {
      width: 100%;
      text-align: center;
      position: relative;
      &::before {
        content: "";
        background-color: $gray-400;
        position: absolute;
        top: 18px;
        left: 0;
        height: 2px;
        width: 100%;
        z-index: -1;
      }
      &::after {
        content: "";
        background-color: $gray-400;
        position: absolute;
        top: 18px;
        right: 0;
        height: 2px;
        width: 50%;
        z-index: -1;
      }
      p {
        margin-left: 0;
        @include font(1rem, 400, $gray-900);
        color: #c2c4cb;
      }
      .circle {
        width: 36px;
        height: 36px;
        margin: 0 auto;
        &::after {
          content: none;
        }
        mat-icon {
          @include font(1.375rem, 400, $white);
          @extend .d-flex;
        }
      }
      &:nth-last-child(2) {
        p {
          margin-left: 0;
        }
      }
      &:last-child {
        p {
          margin-left: 0;
        }
      }
      &.active {
        .circle {
          background: $green;
        }
        &::before {
          background-color: $green;
          // @include bp(exlg-min){
          //   left:100px;
          // }
        }
        &::after {
          background-color: $green;
        }

        p.active {
          color: $white;
        }
        p.text-success {
          cursor: pointer;
        }
      }
    }
  }
}
