.card {
  // background-image: linear-gradient(
  //   132deg,
  //   #292f47 -18%,
  //   #2d446e 62%,
  //   #3261a5 119%

  //   /*95.55deg,
  //   #2B3C60 13.79%, 
  //   #2D4876 84.34%*/

  // );
  background-image: linear-gradient(to right, #2B826C 30%, #3ABA98 100%);
  @include radius(20px);
  padding: 1rem;
  min-width: 340px;
  margin-right: 1rem;
  color: $white;
  mat-icon {
    &.icon-id-card {
      /* @include font(2.8rem); */
      font-size: 40px;
      width: 44px;
      margin: 0;
      height: 30px;
      /*height: 44px;*/
      text-align: center;
    }
  }
  .card-currency {
    margin-left: 2.3rem;
    margin-top: 0.3rem;
    font-size: 20px;
    font-weight: 700;
  }
}
.card-details {
  .view-id, a {
    color: $white;
    &:hover {
      color: $white;
    }
  }
}
.large-text-size .user-name .d-flex{
  display: block;
}
.large-text-size .user-name .d-flex .title-12{
  display: block;
}
.large-text-size .user-name .d-flex .title-10{
  margin-left: 0!important;
}
.large-text-size .user-detail {
  padding: 0.3rem 0rem 1rem;
}
.small-text-size .user-detail .icon-user{
  position: relative;
  top: 2px;
}
.medium-text-size .user-detail .icon-user{
  position: relative;
  top: 5px;
}
.large-text-size .user-detail .icon-user{
  position: relative;
  top: 5px;
}
.large-text-size .card .title-12{
  font-size: 13px!important;
}
.large-text-size .cdk-overlay-container .filter-box h4 {
  padding-left: 0.8rem;
}
.large-text-size .cdk-overlay-container .filter-box h4 {
  padding-bottom: 0.4125rem;
}
.large-text-size .cdk-overlay-container .filter-box.language-dropdown {
  padding-bottom: 10px;
}

.dropdown {
  margin-left: 0.5rem;
  mat-icon {
    @include font(0.83rem);
    width: auto;
    height: auto;
    margin: 0;
  }
}

.user-name {
  span + span {
    margin-left: 0.2rem;
  }
}
.user-logout{
  padding: 0 1rem;
}

.user-detail {
  position: relative;
  /*background: $dark-blue-shade;*/
  @include radius(0.625rem);
  padding: 0.5rem 0.5rem 1rem;
  @include transition(all linear 0.4s);
  min-width: 220px;
  &.open-card {
    .dropdown-list {
      visibility: visible;
    }
  }
}
.dropdown-list {
  visibility: hidden;
  position: absolute;
  background: $dark-blue-shade;
  @include radius(0.625rem);
  top: 4rem;
  left: 0;
  padding: 0.5rem;
  width: 100%;
  span {
    @include font(0.8125rem, 300, $white);
  }
  mat-icon {
    @include font(0.75rem);
    margin: 0;
  }
  .mat-list-base .mat-list-item,
  .mat-list-base .mat-list-option {
    height: auto;
    border-top: 1px solid $border-blue-color;
    padding: 0.25rem 0;
  }
}

.custom-block-width {
  width: 33.33%;
}

.custom-width {
  width: 66.67%;
}
.padding-box {
  padding: 1rem 1.2rem;
}
.or {
  margin: 1.8rem 1.5rem 0.3125rem;
}

/* Map */

.mapouter {
  position: relative;
  height: 390px;
  width: 100%;
  @include radius(1.25rem);
  overflow: hidden;
  color: $secondary;
  agm-map {
    overflow: hidden;
    height: 100%;
    width: 100%;
  }
  p {
    color: $secondary;
  }
  .gm-ui-hover-effect {
    top: -4px !important;
    right: -2px !important;
  }

  .gm-style-iw.gm-style-iw-c {
    max-width: 200px !important;
    p {
      margin-bottom: 0;
      margin-top: 4px;
    }
  }
}

.panel-header {
  padding: 0.8rem 1.2rem;
  background: $gray-100;
  h4 {
    @include font(0.9375rem, 700, $tertiary);
    margin-bottom: 0;
  }
  p {
    @include font(0.8125rem, 300, $secondary);
    margin-bottom: 0;
  }
}

span.text-danger {
  @include font(0.75rem, 400);
  display: flex;
}

.icon-image {
  position: absolute !important;
  right: 0.5rem;
  top: 1rem;
  mat-icon {
    margin-right: 0;
    color: $secondary;
    height: auto;
    &.icon-cross {
      @include font(0.8125rem);
      cursor: pointer;
    }
  }
}

.week-block {
  .day-name {
    min-width: 73px;
    display: inline-block;
  }
  &:last-child {
    margin-bottom: 0.9375rem;
  }
}

.breadcrumb {
  li {
    @include font(0.6875rem, 300, $gray-900);
    padding-right: 1.5rem;
    margin-right: 0.5rem;
    position: relative;
    a {
      color: $cyan;
    }

    &:after {
      content: "\e940";
      font-family: "icomoon";
      position: absolute;
      right: 0;
    }
    &:last-child {
      &::after {
        content: none;
      }
    }
  }
}

.success-round {
  .mat-checkbox {
    margin-right: 0.5rem;
  }
  .mat-checkbox-background,
  .mat-checkbox-frame {
    @include radius(50%);
  }
}

.custom-box {
  p {
    @include font(0.8125rem, 400, $secondary);
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @include font(0.9375rem, bold, $secondary);
  }
  &.dial-number {
    p {
      @include font(0.9375rem, bold, $secondary);
      margin-bottom: 2rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.card-input {
  position: relative;
  .form-control {
    padding-right: 2.5rem;
  }
  span {
    position: absolute;
    right: 0.625rem;
    top: 0.5625rem;
  }
}

.center-heading {
  position: relative;
  text-align: center;
  margin-bottom: 1.5625rem;
  .filter-block {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.custom-box p.light-gray-color,
.light-gray-color {
  // color: $gray-500;
  color: #757575;
}

.pricing-page {
  span.text-danger {
    position: absolute;
  }
}
.notification-mesg {
  max-width: 380px;
  @include font(0.875rem, 400);
}

.list-outer {
  .mat-radio-label {
    align-items: flex-start;
  }
}

.print {
  mat-icon {
    @include font(1rem, 600, $secondary);
    margin: 0;
    cursor: pointer;
  }
}
.center-loader {
  position: absolute;
  left: 50%;
  right: 0;
  top: 50%;
  @include transform(translate(0, -50%));
  @include transform(translate(-50%, 0));
  z-index: 1;
}

[margin-10] {
  margin: 1rem;
}

[margin-top-10] {
  margin-top: 1rem;
}

[margin-top-15] {
  margin-top: 1.5rem;
}

[margin-10] {
  margin: 1rem;
}

[margin-15] {
  margin: 1.5rem;
}

[padding-top-10] {
  margin-top: 1rem;
}

[padding-top-15] {
  margin-top: 1.5rem;
}

.filter-panel {
  .mat-menu-item {
    height: 4.5rem;
    padding: 0.5rem 1.5rem;
    margin: 0.5rem 0;
    &:hover
    {
      background-color: transparent !important;
    }

    .select-box {
      &::before {
        top: 0rem !important;
      }
    }
  }

  menu.heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 2rem !important;
    margin: 0;
    padding: 0 0 0.5rem 0;
    border-bottom: 1px solid var(--gray-200);
    &:hover,
    &:focus 
    {
      background-color: transparent !important;
    }

    h4 {
      padding: 0 0 0 1.5rem !important;
      margin: 0 !important;
      border: none;
    }
  }

  menu.action-buttons {
    height: 3.8rem !important;
    text-align: center;
    margin: 0;
  }
}

.text-primary-dark-bg{
  color: $gray;
}

.text-primary {
  color: $magenta!important;
}

.text-link {
  color: $darkseafoam!important;
}

.text-tertiary {
  color: $navy!important;
}

.large-text-size .filter-panel .mat-menu-item{
  margin: 0rem 0;
}

.mat-sort-header-content{
  text-align: left !important;
}
.custom-box table.mat-table thead th.mat-header-cell.center .mat-sort-header-content{ 
  text-align: center !important;
}

app-pharmacy-search {
  width: 100%;
  display: block;
}

.update-profile-dialog {
  position: relative!important;

  .mat-dialog-content {
    max-height: 70vh;
  }
}
.highlight-text-mark {
  background-color: #2B826C;
  color: #FFF;
}

.home-recent-order {
  min-width: 340px;
}

[hide] {
  visibility: hidden;
} 