$white: #fff;
$gray-100: #f9f9f9;
$gray-200: #f8f7fa;
$gray-300: #d4d4d4;
$gray-400: #b4b4b4;
$gray-500: #949494;
$gray-600: #6c757d;
$gray-700: #b4b4b4;
$gray-800: #161c31;
$gray-900: #7c7e88;
$black: #000;
$light-gray: #e9eaec;
$dark-gray: #696d7e;
// Colors
$blue: #3261a5;
$dark-blue: #292f47;
$blue-shade: #1c2237;
$red: #f44336;
$orange: #E22908; /* #f85a3e; */
$dark-bg-orange: #FF7057;
$light-orange: #fe7158;
$yellow: #B35C00; /*#ffa200;*/
$green: #548301; /* #598c00; */
$cyan: #18c4fe;
$dark-blue-shade: #2c4066;
$border-blue-color: #425471;
$success-color: #00C714; /* #0dba00; */
$error-color: #f00000;
$warning-color: #f18800;
$maroon: #c32148;

$primary: $orange;
$secondary: $dark-blue;
$tertiary: $blue;
$quaternary: $light-orange;
$quinary: $gray-700;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;
$white: $white;
$black: $black;

$colors: (
  "primary": $primary,
  "secondary": $secondary,
  "tertiary": $blue,
  "quinary": $quinary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "white": $white,
  "black": $black,
);

// new theme colors 
$navy : #0B4060;
$navy2 : #063051;
$white : #FFFFFF;
$seafoam: #40C09E;
$darkseafoam: #2B826C;
$tabsseafoam: #52AF96;
$lightseafoam: #d9f2ec;
$magenta:#E523C0;
$gray:#F1F1F1;
$lightgray:#F8F7FA;
$lightgraytwo:#CCCCCC;
$darkbgerror : #FF8605;

// Titles
$title: (
  "1": 2.125rem,
  "2": 2rem,
  "3": 1.875rem,
  "4": 1.5625rem,
  "5": 1.5rem,
  "6": 1.375rem,
  "7": 1.25rem,
  "8": 1.125rem,
  "9": 1rem,
  "10": 0.875rem,
  "11": 0.8125rem,
  "12": 0.75rem,
  "13": 0.6875rem,
);
// Anchor
$link-color: $primary;
$link-decoration: none;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: none;

// Body
$body-bg: $white;
$body-color: $dark-blue;

// Fonts
$font-family-sans-serif: "Roboto", sans-serif !important;
$font-family-base: $font-family-sans-serif;

// Font Size
$font-size-base: 1rem;

// Font Weight
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-base: $font-weight-normal;

$font-weight: (
  "300": $font-weight-light,
  "400": $font-weight-normal,
  "500": $font-weight-medium,
  "700": $font-weight-bold,
);

// Icon Size
$icon-size: (
  "1x": 1rem,
  "2x": 2rem,
  "3x": 3rem,
  "4x": 4rem,
  "5x": 5rem,
);

// Font line height
$line-height-base: 1.5;
$line-height-lg: 1.5;
$line-height-sm: 1.5;

// Paragraph
$paragraph-margin-bottom: 1rem;

// Transitions
$transition-base: all 0.2s ease-in-out;

// set global style variables.
:root {
  // colors
  --primary: #{$primary};
  --secondary: #{$dark-blue};
  --tertiary: #{$blue};
  --quaternary: #{$light-orange};
  --quinary: #{$gray-700};
  --success: #{$green};
  --info: #{$cyan};
  --warning: #{$yellow};
  --danger: #{$red};
  --white: #{$white};
  --black: #{$black};
  --light-orange: #{$light-orange};
  --maroon: #{$maroon};
  --gray-200: #{$gray-200};
  --dark-gray: #{$dark-gray};
  --magenta: #{$magenta};

  // font sizes
  --font-weight-light: #{$font-weight-light};
  --font-weight-normal: #{$font-weight-normal};
  --font-weight-medium: #{$font-weight-medium};
  --font-weight-bold: #{$font-weight-bold};
}
