.guest-header {
  padding: 10px 25px;
  @include bp(exlg) {
    padding: 10px 15px 10px 5px;
  }
  .guest-user-text {
    color: $white;
    font-size: 1rem;
    font-weight: $font-weight-light;
    position: relative;
    top: 7px;
  }
  .filter.mr-3{
    width: 60px;
  }
}

.carousel-login-box {
  display: flex;
  padding: 30px 50px 50px;
  @include bp(exlg) {
    padding: 30px 30px 50px;
  }

  .carousel-box {
    width: calc(100% - 480px);
    border-radius: 30px;
    // background: linear-gradient(
    //   124deg,
    //   #4349a6 0%,
    //   #4f3f73 45.98%,
    //   #695399 100%
    // );
    background-image: linear-gradient(to right, #3BB292 55%, #F1F1F1 100%);
    height: 360px;
    @include bp(exlg) {
      width: calc(100% - 440px);
    }
    .swiper-slide {
      display: flex;
      .swiper-text {
        width: 50%;
        padding-left: 80px;
        padding-right: 50px;
        display: flex;
        align-items: center;
        @include bp(exlg) {
          padding-left: 60px;
        }
        h1 {
          color: $white;
          font-size: 2.2rem;
          font-weight: $font-weight-bold;
          line-height: normal;
          // @include bp(exlg) {
          //   font-size: 1.5rem;
          // }
        }
      }
      .swiper-img {
        width: 50%;
        text-align: right;
        overflow: hidden;
      }
    }
  }

  .guest-login-box {
    width: 480px;
    padding: 20px 40px;
    @include bp(exlg) {
      padding: 20px 40px;
      width: 440px;
      margin: 0 auto;
    }

    h2 {
      font-size: 1.5rem;
      line-height: 2rem;
      font-weight: $font-weight-light;
      margin: 0;
      @include bp(exlg) {
        font-size: 1.375rem;
      }
    }

    p {
      font-size: 1.125rem;
      font-weight: $font-weight-light;
      padding: 15px 0;
      margin: 0;
      margin-top: 10px;
    }

    .mat-button-base {
      margin: 0 auto;
      font-size: 1.125rem;
      font-weight: $font-weight-bold;
      width: 300px;
      height: 60px;
      border-radius: 30px;
    }
    .mat-button-base.secondary-button {
      border: 2px solid $darkseafoam; 
      color: $darkseafoam;
      background-color: $white;
      .mat-button-wrapper {
        color: $darkseafoam!important;
      }
    }
    .mat-button-base.mat-stroked-button > span.mat-button-wrapper{
      height: 56px;
      width: 294px;
      display: block;
      border-radius: 30px;
      background: $white;
      position: relative;
      left: -13px; 
      line-height: 56px;
      color: $seafoam;
    }

    * {
      color: $white;
      text-align: center;
    }
  }
}

.download-faq-support-box {
  background: transparent;
  display: flex;
  padding: 0 50px 80px;
  @media screen and (max-width: 760px) {
    display: block;
  }
  @include bp(exlg) {
    padding: 0 30px 80px;
  }

  h3 {
    font-size: 1.25rem;
    font-weight: $font-weight-bold;
    color: $white;
    margin-bottom: 22px;
  }

  .download-faq-box {
    width: calc(100% - 440px);
    @include bp(exlg) {
      width: calc(100% - 400px);
    }
    @media screen and (max-width: 760px) { 
      width: 100%;
    }
  }

  .customer-support-box {
    width: 440px;
    @include bp(exlg) {
      width: auto;
    }
    .customer-support-container {
      min-height: 182px;
      background: $white;
      border-radius: 10px;
      padding: 15px 0;

      .customer-support-row {
        display: flex;
        padding: 10px 0;

        .customer-support-icon {
          width: 30%;
          text-align: center;
        }

        .customer-support-content {
          p {
            margin: 0;
          }

          .customer-support-text {
            font-size: 0.875rem;
            color: $black;
            font-weight: $font-weight-bold;
          }

          .customer-support-number {
            color: $seafoam;
            font-size: 1.125rem;
            font-family: Roboto;
            font-weight: 500;
          }

          .member-service-text {
            color:$seafoam;
            font-size: 1rem;
            font-family: Roboto;
            font-weight: 500;
          }
        }
      }
    }
  }

  .download-forms-box {
    font-size: 0.875rem;
    font-weight: 500;

    .download-forms-list {
      display: flex;
      flex-flow: wrap;

      .pdf-icon-list {
        position: relative;
        background: $white;
        width: 30.15%;
        margin-right: 3%;
        height: 76px;
        align-items: center;
        display: flex;
        border-radius: 10px;
        padding-left: 65px;
        padding-right: 10px;
        margin-bottom: 30px;
        // cursor: pointer;

        a {
          color: #292f47;
        }
      }

      .pdf-icon-list:before {
        position: absolute;
        content: "";
        display: block;
        width: 30px;
        height: 36px;
        background: url("../images/icon-pdf.svg") no-repeat;
        background-size: cover;
        left: 20px;
      }
    }

    .empty-box-height {
      width: 96%;
      min-height: 145px;
    }
  }

  .faq-box {
    font-size: 0.875rem;
    font-weight: 500;

    .faqs-list {
      display: flex;
      flex-flow: wrap;

      .quiz-icon-list {
        position: relative;
        background: $white;
        width: 47%;
        min-width: 47%;
        margin-right: 2%;
        height: 76px;
        align-items: center;
        display: flex;
        border-radius: 10px;
        padding-left: 65px;
        margin-bottom: 30px;
        padding-right: 10px;
        cursor: pointer;
      }

      .quiz-icon-list:before {
        position: absolute;
        content: "";
        display: block;
        width: 30px;
        height: 30px;
        background: url("../images/icon-quiz.svg") no-repeat;
        background-size: cover;
        left: 20px;
      }
    }
  }
}

footer {
  display: flex;
  border-top: 1px solid $blue;
  padding: 5px 0;
  width: 100%;
  background-color: $navy;
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 1;
  ul {
    display: flex;
    // width: 400px;
    margin: 0 auto;

    li {
      padding: 11px 20px;
      position: relative;

      &:before {
        position: absolute;
        width: 1px;
        height: 13px;
        background:#E523C0;
        content: "";
        top: 17px;
        left: 0px;
      }

      &:first-child {
        &:before {
          display: none;
        }
      }

      a,
      a:not([href]) {
        font-size: 0.875rem;
        color: $gray;
        cursor: pointer;
        &:hover {
          color:$white;
        }
      }
    }
  }
}

.swiper-pagination-bullet-active {
  background: var(--white) !important;
}

@media screen and (max-width: 1366px) {
  .swiper-img {
    width: 450px;
    img{
      width: 420px;
      margin-top: 30px;
      margin-left: -115px;
    }
  }
}
@media screen and (max-width: 767px) {
  .carousel-login-box .carousel-box {display: none;}
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .download-faq-support-box .faq-box .faqs-list .quiz-icon-list {
    width: 95%;
  }
}
@media screen and (max-width: 850px) {
  .swiper-img img {display: none;}
  .carousel-login-box .carousel-box .swiper-slide .swiper-text h1 {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 480px) {
  .download-faq-support-box .faq-box .faqs-list .quiz-icon-list {
    font-size: 0.8rem;
    padding-left: 45px;
    width: 100%;
    &::before {
      width: 24px;
      height: 24px;
      left: 12px;
    }
  }
  .mat-toolbar .mat-toolbar-row {
    padding-left: 10px;
  }
  .carousel-login-box .guest-login-box {
    padding: 20px 10px;
  }
  .guest-header .guest-user-text {
    font-size: 0.6rem;
    top:0
  }
}
@media screen and (max-width: 390px) {
  .cdk-overlay-container .filter-box.language-dropdown {
    width: 145px;
  }
  .filter-box .mat-menu-item {
      padding: 0 1rem;
  }
  .filter-box h4 {
    border-bottom: 1px solid #f8f7fa;
    padding-left: 0.7rem;
    padding-bottom: 0.4125rem;
    font-size: 0.9375rem;
    line-height: 1.3375rem;
  }
  .guest-header .guest-user-text {
    display: none;
  }
  .carousel-login-box {
    padding: 0;
  }
}