.d-none {
    display: none;
}

.d-inline {
    display: inline;
}

.d-inline-block {
    display: inline-block;
}

.d-block {
    display: block;
}

.d-table {
    display: table;
}

.d-table-row {
    display: table-row;
}

.d-table-cell {
    display: table-cell;
}

.d-flex {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}

.d-inline-flex {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}

.flex-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
}

.flex-column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}

.flex-row-reverse {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
}

.flex-column-reverse {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
}

.flex-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.flex-nowrap {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}

.flex-wrap-reverse {
    -ms-flex-wrap: wrap-reverse;
    flex-wrap: wrap-reverse;
}

.justify-content-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

.justify-content-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.justify-content-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.justify-content-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.justify-content-around {
    -ms-flex-pack: distribute;
    justify-content: space-around;
}

.align-items-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
}

.align-items-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.align-items-center {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.align-items-baseline {
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
}

.align-items-stretch {
    -webkit-box-align: stretch;
    -ms-flex-align: stretch;
    align-items: stretch;
}

.align-content-start {
    -ms-flex-line-pack: start;
    align-content: flex-start;
}

.align-content-end {
    -ms-flex-line-pack: end;
    align-content: flex-end;
}

.align-content-center {
    -ms-flex-line-pack: center;
    align-content: center;
}

.align-content-between {
    -ms-flex-line-pack: justify;
    align-content: space-between;
}

.align-content-around {
    -ms-flex-line-pack: distribute;
    align-content: space-around;
}

.align-content-stretch {
    -ms-flex-line-pack: stretch;
    align-content: stretch;
}

.align-self-auto {
    -ms-flex-item-align: auto;
    align-self: auto;
}

.align-self-start {
    -ms-flex-item-align: start;
    align-self: flex-start;
}

.align-self-end {
    -ms-flex-item-align: end;
    align-self: flex-end;
}

.align-self-center {
    -ms-flex-item-align: center;
    align-self: center;
}

.align-self-baseline {
    -ms-flex-item-align: baseline;
    align-self: baseline;
}

.align-self-stretch {
    -ms-flex-item-align: stretch;
    align-self: stretch;
}

.relative {
    position: relative;
}

.full-width {
    width: 100%;
}

.half-width {
    width: 50%;
}

.full-height {
    height: 100%;
}

.word-wrap {
    white-space: pre-line;
    word-break: break-word;
}

.no-wrap{
  white-space:nowrap;
}

.ellipsis {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    display: inline-block;
}

.center {
    text-align: center;
}

.left {
    text-align: left;
}

.right {
    text-align: right;
}

.pointer {
    cursor: pointer;
}

.no-event {
    pointer-events: none;
}

.uppercase {
    text-transform: uppercase;
}

.capitalize {
    text-transform: capitalize;
}

.overflow-x {
    overflow-y: hidden;
    overflow-x: auto;
}

.overflow-y {
    overflow-x: hidden;
    overflow-y: auto;
}

.overflow {
    overflow: auto;
}

.hidden {
    overflow: hidden;
}

.border-none {
    border: none;
}

.auto-margin {
    margin: auto;
}

.no-margin {
    margin: 0 !important;
}

.no-padding {
    padding: 0 !important;
}

.pb-0 {
    padding-bottom: 0 !important;
}

.pl-0 {
    padding-left: 0 !important;
}

.pt-0 {
    padding-top: 0!important;
}

.pt-1 {
    padding-top: 1rem;
}

.mat-body .mt-1,
.mat-body-1 .mt-1,
.mat-typography .mt-1,
.mt-1 {
    margin-top: 0.5rem;
}

.mat-body .mt-2,
.mat-body-1 .mt-2,
.mat-typography .mt-2,
.mt-2 {
    margin-top: 1rem;
}

.mat-body .mt-3,
.mat-body-1 .mt-3,
.mat-typography .mt-3,
.mt-3 {
    margin-top: 1.5rem;
}

.mat-body .mt-4,
.mat-body-1 .mt-4,
.mat-typography .mt-4,
.mt-4 {
    margin-top: 2rem;
}

.mat-body .ml-1,
.mat-body-1 .ml-1,
.mat-typography .ml-1,
.ml-1 {
    margin-left: 0.5rem;
}

.mat-body .ml-2,
.mat-body-1 .ml-2,
.mat-typography .ml-2,
.ml-2 {
    margin-left: 1rem;
}

.mat-body .ml-3,
.mat-body-1 .ml-3,
.mat-typography .ml-3,
.ml-3 {
    margin-left: 1.5rem;
}

.mat-body .ml-4,
.mat-body-1 .ml-4,
.mat-typography .ml-4,
.ml-4 {
    margin-left: 2rem;
}

.mat-body .mr-0,
.mat-body-1 .mr-0,
.mat-typography .mr-0,
.mr-0 {
    margin-right: 0rem !important;
}

.mat-body .mr-1,
.mat-body-1 .mr-1,
.mat-typography .mr-1,
.mr-1 {
    margin-right: 0.5rem;
}

.mat-body .mr-2,
.mat-body-1 .mr-2,
.mat-typography .mr-2,
.mr-2 {
    margin-right: 1rem;
}

.mat-body .mr-3,
.mat-body-1 .mr-3,
.mat-typography .mr-3,
.mr-3 {
    margin-right: 1.5rem;
}

.mat-body .mr-4,
.mat-body-1 .mr-4,
.mat-typography .mr-4,
.mr-4 {
    margin-right: 2rem;
}

.mat-body .mb-0,
.mat-body-1 .mb-0,
.mat-typography .mb-0,
.mb-0 {
    margin-bottom: 0rem !important;
}

.mat-body .mb-1,
.mat-body-1 .mb-1,
.mat-typography .mb-1,
.mb-1 {
    margin-bottom: 0.5rem;
}

.mat-body .mb-2,
.mat-body-1 .mb-2,
.mat-typography .mb-2,
.mb-2 {
    margin-bottom: 1rem;
}

.mat-body .mb-3,
.mat-body-1 .mb-3,
.mat-typography .mb-3,
.mb-3 {
    margin-bottom: 1.5rem;
}

.mat-body .mb-4,
.mat-body-1 .mb-4,
.mat-typography .mb-4,
.mb-4 {
    margin-bottom: 2rem;
}
.w-75 {
    width: 75%;
}
.w-90 {
  width: 90%;
}
.w-70 {
  width: 70%;
}
.w-50 {
    width: 50%;
}

.w-30 {
  width: 30%;
}
.w-20 {
    width: 20%;
}
.w-25 {
    width: 25%;
}
.w-10 {
  width: 10%;
}


