.custom-box {
  background: $white;
  @include radius(1.25rem);
  padding: 0.8rem 0 1rem;
  &.custom-height {
    // height: calc(100% - 122px);
    max-height: calc(100% - 122px);
    min-height: 462px;
    .loader-circle{
      margin-top: 10%;
    }
  }

  .table-heading {
    padding: 0 1.5rem;
    margin-bottom:1rem;
    h4{
      margin-bottom:0;
    }
  }
  .custom-scrollbar {
    min-height: 182px;
    max-height: 182px;
    overflow-y: auto;
    &.box-scroll {
      overflow-y: hidden;
      @media screen and (min-height: 900px) and (min-width: 1600px) {
        min-height: 38vh;
        max-height: 300px;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
      }
    }
  }
  table.mat-table {
    width: 100%;
    font-family: $font-family-base;
    @include shadow(none);

    thead {
      //background: $gray-200;
      background: $lightseafoam;

      th {
        @include font(0.813rem, 700, $dark-blue);
        &.center{
          .mat-sort-header-container{
            justify-content:center;
          }
        }
        &.mat-header-cell{
          &.center{
            text-align: center;
          }
        }
        padding-right: 10px;
      }
    }
    tr.mat-header-row,
    tr.mat-row,
    tr.mat-footer-row {
      height: 42px;
    }
    tr.mat-row {
      cursor: pointer;
      &:hover, &:focus {
        background: $gray-100;
      }
    }
    td {
      @include font(0.75rem, 400, $dark-blue);
      &:last-child {
        width: 9rem;
        &.last-td-width{
          width:8.75rem;
        }
      }
      &.claim-last-td-width{
        width:5.625rem;
      }
      padding-right: 10px;
      span.status {
        color: #666666;
        &::before {
          content: '';
          display: inline-block;
          width: 9px;
          height: 9px;
          -moz-border-radius: 100%;
          -webkit-border-radius: 100%;
          border-radius: 100%;
          margin-right: 4px;
        }
      }
      span.inprogress {
        &::before { background-color: #FF8400 !important }
      }
      span.tosoon {
        &::before { background-color: #FF8400 !important }
      }
      span.success {
        &::before { background-color: #548301 !important }
      }
      span.norefills {
        &::before { background-color: #0B4060 !important }
      }
      span.transferred {
        &::before { background-color: #0B4060 !important }
      }
      span.waiting {
        &::before { background-color: #E22908 !important }
      }
      span.inactive {
        &::before { background-color: #989897 !important }
      }
      span.cancelled {
        &::before { background-color: #E22908 !important }
      }
    }
    th.mat-header-cell:last-of-type,
    td.mat-cell:last-of-type,
    td.mat-footer-cell:last-of-type {
      padding-right: 0.625rem;
    }
  }
}

.error-mesg {
  text-align: center;
  margin: 1.5rem 0 0;
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
  p {
    margin-top: 0.2rem;
    margin-bottom: 0;
  }
  p + p {
    margin-top: 0.2rem;
  }
}

.last-th-width {
  width: 148px;
}
.mat-paginator {
  background: #04223a;
  @include radius(2.5rem);
  margin-top: 1.5rem;
  padding: 0 0.5rem;
  color: $gray-100;
  .mat-select-value {
    color: $gray-100;
  }
  .mat-paginator-range-label {
    margin: 0 1.25rem;
    min-width: 72px;
  }
  .mat-paginator-container {
    min-height: auto;
  }
  .mat-paginator-page-size-select {
    margin-top: 0;
  }
  .mat-icon-button.mat-button-base {
    color: $seafoam;
  }
  .mat-button.mat-primary.mat-button-disabled,
  .mat-button.mat-accent.mat-button-disabled,
  .mat-button.mat-warn.mat-button-disabled,
  .mat-button.mat-button-disabled.mat-button-disabled,
  .mat-icon-button.mat-primary.mat-button-disabled,
  .mat-icon-button.mat-accent.mat-button-disabled,
  .mat-icon-button.mat-warn.mat-button-disabled,
  .mat-icon-button.mat-button-disabled.mat-button-disabled,
  .mat-stroked-button.mat-primary.mat-button-disabled,
  .mat-stroked-button.mat-accent.mat-button-disabled,
  .mat-stroked-button.mat-warn.mat-button-disabled,
  .mat-stroked-button.mat-button-disabled.mat-button-disabled {
    color: $gray-900;
  }
  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: $gray-100;
    display: none;
  }
  .mat-select-arrow {
    border-top: 5px solid $gray-100;
  }
  .mat-paginator-page-size{
    position:relative;
    top:3px;
  }
}

.td-space{
  margin:0.5rem 0;
  p{
    margin-bottom:0;
  }
}
